<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="text-center">
    <h3 class="mt-5 text-sm font-medium text-gray-900">Page Not Found. 404</h3>
    <p class="mt-1 text-sm text-gray-500">The link you are trying to access could not be found.</p>
    <div class="mt-6">
      <button type="button" @click="$router.back" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
        <RewindIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Go Back
      </button>
    </div>
  </div>
</template>

<script>
import { RewindIcon } from '@heroicons/vue/solid'

export default {
  components: {
    RewindIcon
  }
}
</script>
