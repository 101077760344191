<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Edit a Location</h1>
        <p class="mt-2 text-sm text-gray-700">
          Edit and save your location
        </p>
      </div>
    </div>
     <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Name </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" name="name" v-model="location.name" id="name" autocomplete="given-name" class="max-w-lg block w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="comapnyId" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Company </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="comapnyId" name="comapnyId" v-model="location.company_id" autocomplete="comapnyId-name" class="max-w-lg block focus:ring-orange-500 focus:border-orange-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option value="1">Tengy</option>
              </select>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="comapnyId" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Events </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="comapnyId" name="comapnyId" v-model="selected_id" autocomplete="comapnyId-name" class="max-w-lg block focus:ring-orange-500 focus:border-orange-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">

                  <option :value="event.id" v-for="(event) in events" :key="event.id">{{event.name}}</option>

              </select>
            </div>
          </div>

        </div>
      <div class="space-y-6 sm:space-y-5">
        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Active | {{ location.is_active == '1' ? 'Yes' : 'No' }}

          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="checkbox"
              name="is_active"
              v-model="location.is_active"
              id="is_active"
              autocomplete="given-name"
              :value="location.is_active"
              class="
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md"
            />
          </div>

        </div>
      </div>
      <div class="space-y-6 sm:space-y-5">
        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Zero Rated | {{ location.is_active == '1' ? 'Yes' : 'No' }}

          </label>

          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="checkbox"
              name="is_zero_rated"
              v-model="location.is_zero_rated"
              id="is_zero_rated"
              autocomplete="given-name"
              :value="location.is_zero_rated"
              class="
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md"
            />
          </div>
        </div>
      </div>
      </div>
      <br>
            <div class="mt-4">
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-orange-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-orange-700
            focus:outline-none
            focus:ring-2
            focus:ring-orange-500
            focus:ring-offset-2
            sm:w-auto
          "
          @click="updateLocation()"
        >
          Update
        </button>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  created () {
    this.moment = moment
  },
  mounted () {
    this.getLocation()
    this.getEvents()
  },
  data: () => ({
    location: {
      name: '',
      company_id: 1,
      event_id: 1,
      is_active: null,
      is_zero_rated: null
    },
    selected_id: 0,
    events: ''
  }),
  methods: {
    updateLocation () {
      this.axios.patch('api-admin/location/' + this.location.id, this.location).then((res) => {
        this.$toast.show('Location has been updated.', {
          position: 'top-right'
        })
        this.$router.push('/dashboard/locations-list')
      })
    },
    onUpdate (model) {
      console.log(model)
    },
    getLocation () {
      this.axios.get('api-admin/location/' + this.$route.params.id).then((res) => {
        this.location = res.data
      }
      )
    },
    getEvents () {
      this.axios.get('api-admin/event').then((res) => {
        this.events = res.data.data
      }
      )
    }
  }
}
</script>
