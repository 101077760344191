<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Edit an Item</h1>
        <p class="mt-2 text-sm text-gray-700">Edit an item</p>
      </div>
    </div>
    <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
      <div class="space-y-6 sm:space-y-5">
        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Name
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="name"
              v-model="item.name"
              id="name"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Description
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="description"
              v-model="item.description"
              id="description"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Price
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="number"
              name="description"
              v-model="item.price"
              id="description"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Stock
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="number"
              name="description"
              v-model="item.stock_available"
              id="description"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="comapnyId"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Category
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="comapnyId"
              name="comapnyId"
              v-model="item.category_id"
              autocomplete="comapnyId-name"
              class="
                max-w-lg
                block
                focus:ring-orange-500 focus:border-orange-500
                w-full
                shadow-sm
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            >
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>

        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
        <file-pond
          name="test"
          ref="pond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="true"
          accepted-file-types="image/jpeg, image/png"
          v-bind:files="myFiles"
          v-on:init="handleFilePondInit"
        />
          <div>
            <img :src="item.fullurl" class="rounded bg-orange-300 mx-10 object-scale-down h-48 w-48" alt="">
          </div>
          <p v-if="!item.fullurl">No image uploaded for this product.</p>
        </div>
      </div>
    </div>
    <br />
    <div class="mt-4">
      <button
        type="button"
        class="
          inline-flex
          items-center
          justify-center
          rounded-md
          border border-transparent
          bg-orange-600
          px-4
          py-2
          text-sm
          font-medium
          text-white
          shadow-sm
          hover:bg-orange-700
          focus:outline-none
          focus:ring-2
          focus:ring-orange-500
          focus:ring-offset-2
          sm:w-auto
        "
        @click="saveItem()"
      >
        Save Item
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
export default {
  components: {
    FilePond
  },
  created () {
    this.getCategories()
    this.moment = moment
  },
  mounted () {
    this.getItem()
  },
  data: () => ({
    myFiles: [],
    item: {
      name: '',
      location_id: 1,
      is_published: 1,
      image_url: ''
    },
    categories: []
  }),
  methods: {
    handleFilePondInit: function () {
      console.log('FilePond has initialized')

      // FilePond instance methods are available on `this.$refs.pond`
    },
    saveItem () {
      const formData = new FormData()
      if (this.$refs.pond.getFiles().length) {
        formData.append('image', this.$refs.pond.getFiles()[0].file)
      }
      formData.append('_method', 'PUT')
      formData.append('category_id', this.item.category_id)
      formData.append('name', this.item.name)
      formData.append('description', this.item.description)
      formData.append('price', this.item.price)
      formData.append('stock_available', this.item.stock_available)
      this.axios
        .post('api-admin/item/' + this.$route.params.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.$toast.show('Item has been updated.', {
            position: 'top-right'
          })
          this.$router.push('/dashboard/items-list')
        })
    },
    getItem () {
      this.axios.get('api-admin/item/' + this.$route.params.id).then((res) => {
        this.item = res.data
      })
    },
    getCategories () {
      this.axios.get('api-admin/category').then((res) => {
        this.categories = res.data.data
      })
    }
  }
}
</script>
