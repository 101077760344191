import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Auth/LoginView.vue'

import Dashboard from '../layouts/DashboardLayout.vue'

import ReportStats from '../views/Dashboard/Reports/Stats.vue'

import UserList from '../views/Dashboard/Users/UsersList.vue'
import UserView from '../views/Dashboard/Users/UserView.vue'
import UserEdit from '../views/Dashboard/Users/UserEdit.vue'

import CompanyList from '../views/Dashboard/Company/CompanyList.vue'

import LocationsList from '../views/Dashboard/Location/LocationsList.vue'
import LocationsAdd from '../views/Dashboard/Location/LocationsAdd.vue'
import LocationsEdit from '../views/Dashboard/Location/LocationsEdit.vue'

import EventList from '../views/Dashboard/Event/EventList.vue'
import EventAdd from '../views/Dashboard/Event/EventAdd.vue'
import EventEdit from '../views/Dashboard/Event/EventEdit.vue'

import ServiceFeeList from '../views/Dashboard/ServiceFee/ServiceFeeList.vue'
import ServiceFeeAdd from '../views/Dashboard/ServiceFee/ServiceFeeAdd.vue'
import ServiceFeeEdit from '../views/Dashboard/ServiceFee/ServiceFeeEdit.vue'

import MenusList from '../views/Dashboard/Menu/MenusList.vue'
import MenusAdd from '../views/Dashboard/Menu/MenusAdd.vue'
import MenusEdit from '../views/Dashboard/Menu/MenusEdit.vue'

import CategoriesEdit from '../views/Dashboard/Categories/CategoriesEdit.vue'
import CategoriesList from '../views/Dashboard/Categories/CategoriesList.vue'
import CategoriesAdd from '../views/Dashboard/Categories/CategoriesAdd.vue'

import ItemsList from '../views/Dashboard/Item/ItemsList.vue'
import ItemsAdd from '../views/Dashboard/Item/ItemsAdd.vue'
import ItemsEdit from '../views/Dashboard/Item/ItemsEdit.vue'

import OrderHistory from '../views/Dashboard/Orders/OrderHistory.vue'

import PaymentList from '../views/Dashboard/Payments/PaymentsList.vue'

import PageNotFound from '../views/PageNotFound.vue'
import NotAuthorised from '../views/NotAuthorised.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true
    },
    children: [
      {
        path: '/',
        name: 'report-stats',
        component: ReportStats
      },
      {
        path: 'users-list',
        name: 'users-list',
        component: UserList
      },
      {
        path: 'company-list',
        name: 'company-list',
        component: CompanyList
      },

      {
        path: 'locations-add',
        name: 'locations-add',
        component: LocationsAdd
      },
      {
        path: 'locations-list',
        name: 'locations-list',
        component: LocationsList
      },
      {
        path: 'locations-edit/:id',
        name: 'locations-edit',
        component: LocationsEdit
      },

      {
        path: 'event-add',
        name: 'event-add',
        component: EventAdd
      },
      {
        path: 'event-list',
        name: 'event-list',
        component: EventList
      },
      {
        path: 'event-edit/:id',
        name: 'event-edit',
        component: EventEdit
      },

      {
        path: 'service-fee-add',
        name: 'service-fee-add',
        component: ServiceFeeAdd
      },
      {
        path: 'service-fee-list',
        name: 'service-fee-list',
        component: ServiceFeeList
      },
      {
        path: 'service-fee-edit/:id',
        name: 'service-fee-edit',
        component: ServiceFeeEdit
      },

      {
        path: 'categories-add',
        name: 'categories-add',
        component: CategoriesAdd
      },
      {
        path: 'categories-list',
        name: 'categories-list',
        component: CategoriesList
      },
      {
        path: 'categories-edit/:id',
        name: 'categories-edit',
        component: CategoriesEdit
      },

      {
        path: 'menus-add',
        name: 'menus-add',
        component: MenusAdd
      },
      {
        path: 'menus-list',
        name: 'menus-list',
        component: MenusList
      },
      {
        path: 'menus-edit/:id',
        name: 'menus-edit',
        component: MenusEdit
      },

      {
        path: 'items-add',
        name: 'items-add',
        component: ItemsAdd
      },
      {
        path: 'items-list',
        name: 'items-list',
        component: ItemsList
      },
      {
        path: 'items-edit/:id',
        name: 'items-edit',
        component: ItemsEdit
      },
      {
        path: 'user-view/:id',
        name: 'user-view',
        component: UserView
      },
      {
        path: 'user-edit/:id',
        name: 'user-edit',
        component: UserEdit
      },

      {
        path: 'order-history',
        name: 'order-history',
        component: OrderHistory
      },

      {
        path: 'payments-list',
        name: 'payments-list',
        component: PaymentList
      }

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/not-authorised',
    name: 'not-authorised',
    component: NotAuthorised
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes
})

export default (app) => {
  app.router = router
  app.use(router)
}
