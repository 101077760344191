<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">User - {{user.name +' ' + user.surname}}</h1>
        <p class="mt-2 text-sm text-gray-700">{{ user.name }}</p>
        <p class="mt-2 text-sm text-gray-700">{{ user.surname }}</p>
        <p class="mt-2 text-sm text-gray-700">{{ user.phone }}</p>
        <p class="mt-2 text-sm text-gray-700">{{ user.email }}</p>
        <p class="mt-2 text-sm text-gray-700">
          Joined:
          {{ moment(user.created_at).format("dddd, MMMM YYYY, h:mm:ss") }}
        </p>
        <hr />
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <!-- <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-orange-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-orange-700
            focus:outline-none
            focus:ring-2
            focus:ring-orange-500
            focus:ring-offset-2
            sm:w-auto
          "
        >
          Invite User
        </button> -->
      </div>
    </div>
    <p class="p-2 text-xl font-semibold text-gray-900">Total Spend: {{ getCost() }}
</p>
    <hr />
    <p class="text-xl font-semibold text-gray-900 pt-5">Orders</p>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <template v-for="order in user.orders" :key="order.id">
            <div
              class="
                overflow-hidden
                shadow
                ring-1 ring-black ring-opacity-5
                md:rounded-lg
                p-5
                mb-5
              "
            >
              <p>Order Id: {{ order.id }}</p>
              <p>Order UUID: {{ order.uuid }}</p>
              <p>Order Status: {{ order.order_status.status }}</p>
              <hr />

              <p>Location: {{ order.location.name }}</p>
              <p>Company: {{ order.location.company.name }}</p>
              <hr />
              <span v-for="item in order.order_items" :key="item.id">
                <p>{{ item.name }}</p>
              </span>
              <hr />
              <p>Payment Reference: {{ order.payment.reference }}</p>
              <p>Payment Amount: {{ order.payment.order_amount }}</p>
              <template
                v-if="
                  order.payment.verification_data != null &&
                  order.payment.verification_data != ''
                "
              >
                <p>
                  Payment Verification Message:
                  {{ order.payment.verification_data.message }}
                </p>
                <p>
                  Payment Status: {{ order.payment.verification_data.status }}
                </p>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  created () {
    this.moment = moment
  },
  components: {},
  mounted () {
    this.getUser()
  },
  data: () => ({
    user: {
      orders: []
    }
  }),
  methods: {
    getCost () {
      let cost = 0
      for (let i = 0; i < this.user.orders.length; i++) {
        if (this.user.orders[i].payment.verified) {
          cost = (parseFloat(this.user.orders[i].payment.order_amount) + parseFloat(cost)).toFixed(2)
        }
      }
      return cost
    },
    getUser () {
      this.axios
        .get('api-admin/user/user-orders/' + this.$route.params.id)
        .then((res) => {
          this.user = res.data
        })
    }
  }
}
</script>
