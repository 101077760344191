<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Users</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the users in the system.
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <!-- <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-orange-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-orange-700
            focus:outline-none
            focus:ring-2
            focus:ring-orange-500
            focus:ring-offset-2
            sm:w-auto
          "
        >
          Invite User
        </button> -->
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:w-auto"
            @click="pageFront"
          >
            {{ "<" }}
          </button>

          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md ml-5 border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:w-auto"
            @click="pageBack"
          >
            >
          </button>
        </div>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Verified On
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created On
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="user in users.data" :key="user.email">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-500">{{ user.id }}</div>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img
                          v-if="user.avatar"
                          class="h-10 w-10 rounded-full"
                          :src="user.avatar"
                          alt=""
                        />
                        <UserIcon
                          v-if="!user.avatar"
                          class="mr-4 flex-shrink-0 h-6 w-6 text-orange-300"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          {{ user.name + " " + user.surname }}
                        </div>
                        <div class="text-gray-500">{{ user.email }}</div>
                        <div class="text-gray-500">{{ user.phone }}</div>
                      </div>
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="user.active == 1"
                      class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                      >Active</span
                    >
                    <span v-if="user.active == 0"
                      class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800"
                      >Deactivated</span
                    >
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{
                        moment(user.email_verified_at).format(
                          "dddd, MMMM YYYY, h:mm:ss"
                        )
                      }}
                    </div>
                    <div class="text-gray-500">{{ user.department }}</div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      moment(user.created_at).format("dddd, MMMM YYYY, h:mm:ss")
                    }}
                  </td>
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <button
                      @click="deleteUser(user.id)"
                      class="text-orange-600 hover:text-orange-900 mx-2"
                    >
                      Delete<span class="sr-only">, {{ user.name }}</span>
                    </button>

                    <a
                      :href="'user-edit/' + user.id"
                      class="text-orange-600 hover:text-orange-900"
                      >Edit<span class="sr-only">, {{ user.name }}</span></a
                    >

                    <a
                      :href="'user-view/' + user.id"
                      class="text-orange-600 hover:text-orange-900 mx-2"
                      >View<span class="sr-only">, {{ user.name }}</span></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UserIcon } from '@heroicons/vue/outline'
import moment from 'moment'
export default {
  created () {
    this.moment = moment
  },
  components: {
    UserIcon
  },
  mounted () {
    this.getUsers()
  },
  data: () => ({
    users: [
      {}
      // More users...
    ]
  }),
  methods: {
    getUsers () {
      this.axios.get('api-admin/user').then((res) => {
        this.users = res.data
        // console.log(res.data)
      })
    },
    deleteUser (id) {
      this.axios.delete('api-admin/user/' + id).then((res) => {
        this.getUsers()
        // console.log(res.data)
      })
    },
    pageFront () {
      const page = this.users.current_page - 1
      console.log(this.users.current_page)
      this.axios.get('api-admin/user?page=' + page).then((res) => {
        this.users = res.data
      })
    },
    pageBack () {
      const page = this.users.current_page + 1
      console.log(this.users.current_page)
      this.axios.get('api-admin/user?page=' + page).then((res) => {
        this.users = res.data
      })
    }
  }
}
</script>
