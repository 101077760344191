<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Edit a Service Fee</h1>
        <p class="mt-2 text-sm text-gray-700">Edit and save your service fee</p>
      </div>
    </div>
    <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
      <div class="space-y-6 sm:space-y-5">
        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Name
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="name"
              v-model="serviceFee.name"
              id="name"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
      </div>
      <div class="space-y-6 sm:space-y-5">
        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Percentage
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="name"
              v-model="serviceFee.percentage"
              id="name"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
      </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="comapnyId" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Vendor </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="comapnyId" name="comapnyId" v-model="serviceFee.vendor_id" autocomplete="comapnyId-name" class="max-w-lg block focus:ring-orange-500 focus:border-orange-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
               <option
                v-for="vendor in vendors.data"
                :key="vendor.id"
                :value="vendor.id"
              >
                {{ vendor.name }}
              </option>
              </select>
            </div>
          </div>
      <div class="space-y-6 sm:space-y-5">
        <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Active | {{ serviceFee.active == '1' ? 'Yes' : 'No' }}

          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="checkbox"
              name="active"
              v-model="serviceFee.active"
              id="active"
              autocomplete="given-name"
              :value="serviceFee.active"
              class="
                shadow-sm
                focus:ring-orange-500 focus:border-orange-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="mt-4">
      <button
        type="button"
        class="
          inline-flex
          items-center
          justify-center
          rounded-md
          border border-transparent
          bg-orange-600
          px-4
          py-2
          text-sm
          font-medium
          text-white
          shadow-sm
          hover:bg-orange-700
          focus:outline-none
          focus:ring-2
          focus:ring-orange-500
          focus:ring-offset-2
          sm:w-auto
        "
        @click="serviceFeeUpdate()"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  created () {
    this.moment = moment
  },
  mounted () {
    this.getServiceFee()
    this.getVendors()
  },
  data: () => ({
    serviceFee: {
      name: '',
      address: '',
      active: null
    },
    vendors: []
  }),
  methods: {
    serviceFeeUpdate () {
      this.axios
        .patch('api-admin/service-fee/' + this.serviceFee.id, this.serviceFee)
        .then((res) => {
          this.$toast.show('Service fee has been updated.', {
            position: 'top-right'
          })
          this.$router.push('/dashboard/service-fee-list')
        })
    },
    getVendors () {
      this.axios.get('api-admin/location').then((res) => {
        this.vendors = res.data
      })
    },
    getServiceFee () {
      this.axios.get('api-admin/service-fee/' + this.$route.params.id).then((res) => {
        this.serviceFee = res.data
      })
    }
  }
}
</script>
