<template>
  <div class="flex items-center justify-center h-screen px-6 bg-gray-200">
    <div class="w-full max-w-sm p-6 bg-white rounded-md shadow-md">
      <div class="flex items-center justify-center">
        <img src="@/assets/images/icon.png" alt="tengy" />
        <span class="text-2xl font-semibold text-gray-700"
          >Tengy <br/> Dashboard</span
        >
      </div>

      <form class="mt-4" @submit.prevent="login">
        <label class="block">
          <span class="text-sm text-gray-700">Email</span>
          <input
          autocomplete="email"
            type="email"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-orange-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-orange-500
            "
            v-model="user.username"
          />
        </label>

        <label class="block mt-3">
          <span class="text-sm text-gray-700" >Password</span>
          <input
          autocomplete="current-password"
            type="password"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-orange-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-orange-500
            "
            v-model="user.password"
          />
        </label>

        <div class="flex items-center justify-between mt-4">
          <div>
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="
                  text-orange-600
                  border-gray-200
                  rounded-md
                  focus:border-orange-600
                  focus:ring
                  focus:ring-opacity-40
                  focus:ring-orange-500
                "
              />
              <span class="mx-2 text-sm text-gray-600">Remember me</span>
            </label>
          </div>

          <div>
            <a
              class="block text-sm text-orange-700 fontme hover:underline"
              href="#"
              >Forgot your password?</a
            >
          </div>
        </div>

        <div class="mt-6">
          <button
            @click="login()"
            type="button"
            class="
              w-full
              px-4
              py-2
              text-sm text-center text-white
              bg-orange-600
              rounded-md
              focus:outline-none
              hover:bg-orange-500
            "
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    user: {
      username: '',
      password: '',
      remember: true
    }
  }),
  methods: {
    login () {
      this.$auth
        .login({
          url: 'api-auth/login',
          data: this.user,
          redirect: { name: 'dashboard' },
          fetchUser: false,
          staySignedIn: true
        })
        .then((res) => {
          if (this.user.remember) {
            // auth.remember(JSON.stringify({
            //   name: auth.user().first_name
            // }))
          }
          console.log(res.data.access_token)
          this.$auth.token(null, res.data.access_token, true)
        }, (res) => {
          console.log(res)
          // if (res.status === 400 || res.status === 401) {
          this.$toast.error('Invalid username or password')
        })
    }
  }
}
</script>
