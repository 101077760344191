<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Order History</h1>
        <p class="mt-2 text-sm text-gray-700">Previous fullfilled orders</p>
      </div>
      <!-- <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-orange-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-orange-700
            focus:outline-none
            focus:ring-2
            focus:ring-orange-500
            focus:ring-offset-2
            sm:w-auto
          "
          @click="$router.push('orders-add')"
        >
          Add Category
        </button>
      </div> -->
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="
              overflow-hidden
              shadow
              ring-1 ring-black ring-opacity-5
              md:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      py-3.5
                      pl-4
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="
                      py-3.5
                      pl-4
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Items
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Payments
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Created On
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="order in orders.data" :key="order.id">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          {{ order.id }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="font-medium text-gray-900 capitalize">
                          <p>{{ order.user.name + " " + order.user.surname }}</p>
                          <p>{{order.user.email}}</p>
                          <p>{{order.user.phone}}</p>
                        </div>
                        <div class="font-small text-gray-400">
                          <!-- {{
                            order.menu ? order.menu.name : ''
                          }} -->
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      :class="[
                        order.status_id
                          ? 'text-green-800 bg-green-100'
                          : 'text-red-800 bg-red-100',
                        'inline-flex rounded-full  px-2 text-xs font-semibold leading-5',
                      ]"
                    >
                      {{ order.order_items.length }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div v-if="order.payment" class="text-gray-900">
                      <p>{{ "Amt: " + order.payment.order_amount }}</p>
                      <p>{{ "Ref: " + order.payment.reference }}</p>
                      <p>{{ "Pay Id: " + order.payment.id }}</p>
                      <p>
                        {{
                          "Verfied: " +
                          (order.payment.verified ? "True" : "False")
                        }}
                      </p>
                            <!-- v-if="order.payment.verified" -->
                      <button

                        class="
                          inline-flex
                          items-center
                          justify-center
                          rounded-md
                          border border-transparent
                          bg-orange-600
                          px-4
                          py-2
                          m-3
                          text-sm
                          font-medium
                          text-white
                          shadow-sm
                          hover:bg-orange-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-orange-500
                          focus:ring-offset-2
                          sm:w-auto
                        "
                        @click="verify(order.payment.reference)"
                      >
                        Manual Verify
                      </button>
                      <p class="bg-green text-green-800 bold" v-show="loadVerify">Verifying...</p>
                      <hr />
                      <span v-if="order.payment.verification_data">
                        <p>{{ order.payment.verification_data.message }}</p>
                        <span v-if="order.payment.verification_data.data">
                          <p>
                            {{
                              "Domain: " +
                              order.payment.verification_data.data.domain
                            }}
                          </p>
                          <p>
                            {{
                              "Channel: " +
                              order.payment.verification_data.data.channel
                            }}
                          </p>
                          <p>
                            {{
                              "Currency: " +
                              order.payment.verification_data.data.currency
                            }}
                          </p>
                          <p>
                            {{
                              "Gateway: " +
                              order.payment.verification_data.data
                                .gateway_response
                            }}
                          </p>
                        </span>
                        <hr />
                        <span
                          v-if="
                          order.payment.verification_data.data &&
                            order.payment.verification_data.data.authorization
                          "
                        >
                          <p>
                            {{
                              "Last4: " +
                              order.payment.verification_data.data.authorization
                                .last4
                            }}
                          </p>
                          <p>
                            {{
                              "Bank: " +
                              order.payment.verification_data.data.authorization
                                .bank
                            }}
                          </p>
                          <p>
                            {{
                              "Brand: " +
                              order.payment.verification_data.data.authorization
                                .brand
                            }}
                          </p>
                        </span>
                      </span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{ order.order_status.status }}
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{ order.location.name }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      moment(order.created_at).format(
                        "dddd, MMMM YYYY, h:mm:ss"
                      )
                    }}
                  </td>
                  <td
                    class="
                      relative
                      whitespace-nowrap
                      py-4
                      pl-3
                      pr-4
                      text-right text-sm
                      font-medium
                      sm:pr-6
                    "
                  >
                    <!-- <a href="#" class="text-red-600 hover:text-red-900 mx-3"
                      @click="deleteCategory(order.id)">Delete<span class="sr-only">, {{ order.name }}</span></a
                    > -->
                    <!-- <a href="#" @click="$router.push('orders-edit/'+ order.id)" class="text-orange-600 hover:text-orange-900"
                      >View<span class="sr-only">, {{ order.name }}</span></a
                    > -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { UserIcon } from '@heroicons/vue/outline'
import moment from 'moment'
export default {
  created () {
    this.moment = moment
  },
  components: {
    // UserIcon
  },
  mounted () {
    this.getOrders()
  },
  data: () => ({
    loadVerify: false,
    orders: [
      {
        status_id: '',
        name: '',
        user: {
          name: ''
        },
        location: [
          {
            name: ''
          }
        ],
        order_items: [{}],
        items: []
      }
    ]
  }),
  methods: {
    verify (ref) {
      this.loadVerify = true
      this.axios.get('api-user/order/verify-payment/' + ref).then((res) => {
        this.loadVerify = false
        this.getOrders()
      })
    },
    getOrders () {
      this.axios.get('api-admin/order').then((res) => {
        this.orders = res.data
      })
    }
  }
}
</script>
